.colored-margin {
}

.header {
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    background-color: #000033;
}

.link-hover:hover{
    cursor: pointer;
}
