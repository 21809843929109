.img-container {
    position: relative;
    text-align: center;
    color: white;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    background-color: black;
}

.img-dark {
    opacity: 0.5;
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}