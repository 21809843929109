.App{
    background-color: #f2f2f2;
    color: #494949;
    font-family: 'Roboto', sans-serif;
}

.App h2{
    font-family: 'Raleway', sans-serif;
}

.App h3{
    font-family: 'Raleway', sans-serif;
}

.v1center {
    display: inline-block;
    vertical-align: middle;
    float: none;
}